<template>
  <div class="d-flex flex-column align-center pt-16">
    <!-- Show the animation here -->
    <div style="max-width: 360px">
      <lottie-animation
        loop
        height="200"
        file="36395-lonely-404.json"
      />
    </div>

    <!-- Show a button to homepage -->
    <div class="d-flex justify-center">
      <v-btn
        depressed
        color="primary"
        :to="{ name: 'Dashboard' }"
      >
        <v-icon left>
          keyboard_return
        </v-icon>

        Back to Dashboard
      </v-btn>
    </div>
  </div>
</template>

<script>
// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Export the SFC
export default {
  // Name of the component
  name: "NotFound",

  // Register the children components
  components: {
    LottieAnimation
  }
}
</script>
